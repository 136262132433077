@import "~bootstrap/scss/_functions.scss";
@import "~bootstrap/scss/_variables.scss";
@import "~bootstrap/scss/mixins/_breakpoints.scss";

.font-box {
    height: 140px;
    max-height: 140px;
    overflow: hidden;
    h2 {
        &:hover {
            cursor: pointer;
        }
    }
}
.logo-text {
    font-family: "Putracetol-CavilayScript", sans-serif;
    font-size: 36px !important;
    height: 70px !important;
    img {
        width: 100%;
    }
}
.fave-btn {
    label {
        margin-top: -1rem;
    }
}
.bg-top {
    background-color: rgb(227, 214, 202);
    margin-bottom: 5rem;
    padding-top: 3rem;
    color: #3e3e3e;
    position: relative;
    .wave-border {
        position: absolute;
        height: 100px;
        width: 100%;
        path {
            fill: rgb(227, 214, 202);
        }
    }
}
.font-18 {
    font-size: 18px !important;
}
.font-24 {
    font-size: 24px !important;
}
.dark-bg {
    background-color: #444 !important;
}
.btn-pink {
    color: #c96a66 !important;
    border-color: #c96a66 !important;
    &:hover {
        background-color: rgba(201, 106, 102, .1) !important;
    }
}
.text-outline {
    -webkit-text-stroke: 1px #5a5a5a;
}
.text-shadow-white {
    text-shadow: 0 0 2px rgba(0, 0, 0, .5);
}
.text-shadow {
    text-shadow: 0 0 1px rgba(0, 0, 0, .35);
}

.rect-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    flex-wrap: wrap;
    padding: 0 2.5em;
    width: 80%;
    margin: auto auto 36px auto;
    .btn-light {
        background-color: #fff;
        border-style: dashed;
        width: 48%;
        @include media-breakpoint-down(md) {
            width: 100%;
        };
    }
    .rect {
        width: auto;
        text-align: center;
        margin-bottom: 1em;
        .rect-svg {
            max-width: 200px;
            rect {
                fill: rgb(255,255,255);
                stroke-width: 3;
                stroke: rgb(0,0,0);
            }
        }
    }
}

.nav-item {
    color: #6c757d !important;
    &:hover {
        text-decoration: underline;
    }
}

/* other formats include:
'woff2', 'truetype, 'opentype','embedded-opentype', and 'svg' */
@font-face {
    font-family: 'Aldedesign-WisdomMerry';
    src: local('Aldedesign-WisdomMerry'), url('./fonts/Aldedesign-WisdomMerry.ttf') format('truetype');
}
@font-face {
    font-family: 'Apricots-Regular';
    src: local('Apricots-Regular'), url('./fonts/Apricots-Regular.ttf') format('truetype');
}
@font-face {
    font-family: 'Awanstudio-Aubrielle-Regular';
    src: local('Awanstudio-Aubrielle-Regular'), url('./fonts/Awanstudio-Aubrielle-Regular.otf') format('opentype');
}
@font-face {
    font-family: 'Bralyn';
    src: local('Bralyn'), url('./fonts/Bralyn.otf') format('opentype');
}
@font-face {
    font-family: 'BrittanySignature';
    src: local('BrittanySignature'), url('./fonts/BrittanySignature.ttf') format('truetype');
}
@font-face {
    font-family: 'CultivatedMind-GardenGrownBCaps';
    src: local('CultivatedMind-GardenGrownBCaps'), url('./fonts/CultivatedMind-GardenGrownBCaps.otf') format('opentype');
}
@font-face {
    font-family: 'Datalegreya-Dot';
    src: local('Datalegreya-Dot'), url('./fonts/Datalegreya-Dot.otf') format('opentype');
}
@font-face {
    font-family: 'Dmitrii Chirkov-Joshico-Regular';
    src: local('Dmitrii Chirkov-Joshico-Regular'), url('./fonts/DmitriiChirkov-Joshico-Regular.otf') format('opentype');
}
@font-face {
    font-family: 'Fikryal-HelloSnowflake';
    src: local('Fikryal-HelloSnowflake'), url('./fonts/Fikryal-HelloSnowflake.ttf') format('truetype');
}
@font-face {
    font-family: 'Gistesy';
    src: local('Gistesy'), url('./fonts/Gistesy.ttf') format('truetype');
}
@font-face {
    font-family: 'hellohoney';
    src: local('hellohoney'), url('./fonts/hellohoney.otf') format('opentype');
}
@font-face {
    font-family: 'JeffLevine-InlineRetroJNL';
    src: local('JeffLevine-InlineRetroJNL'), url('./fonts/JeffLevine-InlineRetroJNL.otf') format('opentype');
}
@font-face {
    font-family: 'Khurasan-Sheenaz';
    src: local('Khurasan-Sheenaz'), url('./fonts/Khurasan-Sheenaz.otf') format('opentype');
}
@font-face {
    font-family: 'KimberlyGeswein-KGRiseUP';
    src: local('KimberlyGeswein-KGRiseUP'), url('./fonts/KimberlyGeswein-KGRiseUP.ttf') format('truetype');
}
@font-face {
    font-family: 'LoveloBlack';
    src: local('LoveloBlack'), url('./fonts/LoveloBlack.otf') format('opentype');
}
@font-face {
    font-family: 'MadinaClean';
    src: local('MadinaClean'), url('./fonts/MadinaClean.otf') format('opentype');
}
@font-face {
    font-family: 'MegaType-KameliaScript-Regular';
    src: local('MegaType-KameliaScript-Regular'), url('./fonts/MegaType-KameliaScript-Regular.otf') format('opentype');
}
@font-face {
    font-family: 'MegaType-YasmineGardner';
    src: local('MegaType-YasmineGardner'), url('./fonts/MegaType-YasmineGardner.otf') format('opentype');
}
@font-face {
    font-family: 'Pompiere-Regular';
    src: local('Pompiere-Regular'), url('./fonts/Pompiere-Regular.ttf') format('truetype');
}
@font-face {
    font-family: 'Putracetol-CavilayScript';
    src: local('Putracetol-CavilayScript'), url('./fonts/Putracetol-CavilayScript.otf') format('opentype');
}
@font-face {
    font-family: 'Raleway-Medium';
    src: local('Raleway-Medium'), url('./fonts/Raleway-Medium.ttf') format('truetype');
}
@font-face {
    font-family: 'Samtype-HebrewKarina-Regular';
    src: local('Samtype-HebrewKarina-Regular'), url('./fonts/Samtype-HebrewKarina-Regular.otf') format('opentype');
}
@font-face {
    font-family: 'SoftMaker-TypewriterSerial';
    src: local('SoftMaker-TypewriterSerial'), url('./fonts/SoftMaker-TypewriterSerial.otf') format('opentype');
}
@font-face {
    font-family: 'SophiaRonald';
    src: local('SophiaRonald'), url('./fonts/SophiaRonald.otf') format('opentype');
}
@font-face {
    font-family: 'Typesketchbook-BetmRounded-ExtraLight';
    src: local('Typesketchbook-BetmRounded-ExtraLight'), url('./fonts/Typesketchbook-BetmRounded-ExtraLight.otf') format('opentype');
}
@font-face {
    font-family: 'ZaneStudio-HelloSunriseScript';
    src: local('ZaneStudio-HelloSunriseScript'), url('./fonts/ZaneStudio-HelloSunriseScript.otf') format('opentype');
}
@font-face {
    font-family: 'Alegreya-Regular';
    src: local('Alegreya-Regular'), url('./fonts/Alegreya-Regular.ttf') format('truetype');
}
@font-face {
    font-family: 'BebasNeue-Regular';
    src: local('BebasNeue-Regular'), url('./fonts/BebasNeue-Regular.ttf') format('truetype');
}
@font-face {
    font-family: 'Fontforecast-SaltAndSpicesMonoBold';
    src: local('Fontforecast-SaltAndSpicesMonoBold'), url('./fonts/Fontforecast-SaltAndSpicesMonoBold.otf') format('opentype');
}
@font-face {
    font-family: 'Hanoded-LemonYellowSunExtraBoldRegular';
    src: local('Hanoded-LemonYellowSunExtraBoldRegular'), url('./fonts/Hanoded-LemonYellowSunExtraBoldRegular.otf') format('opentype');
}
@font-face {
    font-family: 'Lemonthe-CaltonEleganceScript';
    src: local('Lemonthe-CaltonEleganceScript'), url('./fonts/Lemonthe-CaltonEleganceScript.ttf') format('truetype');
}
@font-face {
    font-family: 'LibreBaskerville-Regular';
    src: local('LibreBaskerville-Regular'), url('./fonts/LibreBaskerville-Regular.ttf') format('truetype');
}
@font-face {
    font-family: 'MalisiaScript';
    src: local('MalisiaScript'), url('./fonts/MalisiaScript.otf') format('opentype');
}
@font-face {
    font-family: 'Blushed';
    src: local('Blushed'), url('./fonts/Blushed.ttf') format('truetype');
}
@font-face {
    font-family: 'Foxtrot';
    src: local('Foxtrot'), url('./fonts/Foxtrot.ttf') format('truetype');
}
@font-face {
    font-family: 'Gingerly';
    src: local('Gingerly'), url('./fonts/Gingerly.ttf') format('truetype');
}
@font-face {
    font-family: 'HoneyButter';
    src: local('HoneyButter'), url('./fonts/HoneyButter.ttf') format('truetype');
}
@font-face {
    font-family: 'Backroads';
    src: local('Backroads'), url('./fonts/Backroads.ttf') format('truetype');
}
@font-face {
    font-family: 'BonVivantSerifBold';
    src: local('BonVivantSerifBold'), url('./fonts/BonVivantSerifBold.ttf') format('truetype');
}
@font-face {
    font-family: 'ColdBrew';
    src: local('ColdBrew'), url('./fonts/ColdBrew.ttf') format('truetype');
}
@font-face {
    font-family: 'Elementary';
    src: local('Elementary'), url('./fonts/Elementary.ttf') format('truetype');
}
@font-face {
    font-family: 'GeorgiaPeach';
    src: local('GeorgiaPeach'), url('./fonts/GeorgiaPeach.ttf') format('truetype');
}
@font-face {
    font-family: 'Imaginary';
    src: local('Imaginary'), url('./fonts/Imaginary.ttf') format('truetype');
}
@font-face {
    font-family: 'MagicDust';
    src: local('MagicDust'), url('./fonts/MagicDust.ttf') format('truetype');
}
@font-face {
    font-family: 'Peachy';
    src: local('Peachy'), url('./fonts/Peachy.ttf') format('truetype');
}
@font-face {
    font-family: 'Roselyn2';
    src: local('Roselyn2'), url('./fonts/Roselyn2.ttf') format('truetype');
}
@font-face {
    font-family: 'SafeHolidayFamily';
    src: local('SafeHolidayFamily'), url('./fonts/SafeHolidayFamily.otf') format('opentype');
}
@font-face {
    font-family: 'Sparkle';
    src: local('Sparkle'), url('./fonts/Sparkle.ttf') format('truetype');
}
@font-face {
    font-family: 'WildMango';
    src: local('WildMango'), url('./fonts/WildMango.ttf') format('truetype');
}
////////////////////////////
// new fonts - script
@font-face {
    font-family: 'Bluebird';
    src: local('Bluebird'), url('./fonts/Bluebird.ttf') format('truetype');
}
@font-face {
    font-family: 'CadosaScript';
    src: local('CadosaScript'), url('./fonts/CadosaScript.ttf') format('truetype');
}
@font-face {
    font-family: 'CaramelEspresso';
    src: local('CaramelEspresso'), url('./fonts/CaramelEspresso.ttf') format('truetype');
}
@font-face {
    font-family: 'Charmed';
    src: local('Charmed'), url('./fonts/Charmed.ttf') format('truetype');
}
@font-face {
    font-family: 'Honeybun';
    src: local('Honeybun'), url('./fonts/Honeybun.ttf') format('truetype');
}
@font-face {
    font-family: 'Hopeful';
    src: local('Hopeful'), url('./fonts/Hopeful.ttf') format('truetype');
}
@font-face {
    font-family: 'Julietta';
    src: local('Julietta'), url('./fonts/Julietta.otf') format('opentype');
}
@font-face {
    font-family: 'Sugardrop';
    src: local('Sugardrop'), url('./fonts/Sugardrop.ttf') format('truetype');
}
@font-face {
    font-family: 'SummerBerries';
    src: local('SummerBerries'), url('./fonts/SummerBerries.ttf') format('truetype');
}
@font-face {
    font-family: 'Aisyah-Demo';
    src: local('Aisyah-Demo'), url('./fonts/Aisyah-Demo.otf') format('opentype');
}
@font-face {
    font-family: 'GlooryScript-Regular';
    src: local('GlooryScript-Regular'), url('./fonts/GlooryScript-Regular.ttf') format('truetype');
}
@font-face {
    font-family: 'Kimberly';
    src: local('Kimberly'), url('./fonts/Kimberly.otf') format('opentype');
}
@font-face {
    font-family: 'Melinda-script';
    src: local('Melinda-script'), url('./fonts/Melinda-script.ttf') format('truetype');
}
@font-face {
    font-family: 'NotoSansGurmukhi';
    src: local('NotoSansGurmukhi'), url('./fonts/NotoSansGurmukhi.ttf') format('truetype');
}
@font-face {
    font-family: 'Saffron';
    src: local('Saffron'), url('./fonts/Saffron.ttf') format('truetype');
}
@font-face {
    font-family: 'Adore';
    src: local('Adore'), url('./fonts/Adore.otf') format('opentype');
}
@font-face {
    font-family: 'BlancheSemiBold';
    src: local('BlancheSemiBold'), url('./fonts/BlancheSemiBold.otf') format('opentype');
}
@font-face {
    font-family: 'CanyonlandsScript';
    src: local('CanyonlandsScript'), url('./fonts/CanyonlandsScript.otf') format('opentype');
}
@font-face {
    font-family: 'FantastiqueSans';
    src: local('FantastiqueSans'), url('./fonts/FantastiqueSans.otf') format('opentype');
}
@font-face {
    font-family: 'FantastiqueScript';
    src: local('FantastiqueScript'), url('./fonts/FantastiqueScript.otf') format('opentype');
}
@font-face {
    font-family: 'MissMagnolia-Script';
    src: local('MissMagnolia-Script'), url('./fonts/MissMagnolia-Script.otf') format('opentype');
}
@font-face {
    font-family: 'ModernBohemianSans-Regular';
    src: local('ModernBohemianSans-Regular'), url('./fonts/ModernBohemianSans-Regular.otf') format('opentype');
}
@font-face {
    font-family: 'ModernBohemianScript-Regular';
    src: local('ModernBohemianScript-Regular'), url('./fonts/ModernBohemianScript-Regular.otf') format('opentype');
}
@font-face {
    font-family: 'MontereyScript';
    src: local('MontereyScript'), url('./fonts/MontereyScript.otf') format('opentype');
}
@font-face {
    font-family: 'MontlakeRoad';
    src: local('MontlakeRoad'), url('./fonts/MontlakeRoad.otf') format('opentype');
}
@font-face {
    font-family: 'Neutraface-Text-Book';
    src: local('Neutraface-Text-Book'), url('./fonts/Neutraface-Text-Book.otf') format('opentype');
}
@font-face {
    font-family: 'Neutraface-Text-Book-Italic';
    src: local('Neutraface-Text-Book-Italic'), url('./fonts/Neutraface-Text-Book-Italic.otf') format('opentype');
}
@font-face {
    font-family: 'Sangria';
    src: local('Sangria'), url('./fonts/Sangria.otf') format('opentype');
}
@font-face {
    font-family: 'Somersette';
    src: local('Somersette'), url('./fonts/Somersette.otf') format('opentype');
}
@font-face {
    font-family: 'SummerLemonade-SansTwo';
    src: local('SummerLemonade-SansTwo'), url('./fonts/SummerLemonade-SansTwo.otf') format('opentype');
}
@font-face {
    font-family: 'SummerLemonade-Script';
    src: local('SummerLemonade-Script'), url('./fonts/SummerLemonade-Script.otf') format('opentype');
}
@font-face {
    font-family: 'Montserrat-Medium';
    src: local('Montserrat-Medium'), url('./fonts/Montserrat-Medium.ttf') format('truetype');
}
@font-face {
    font-family: 'Montserrat-MediumItalic';
    src: local('Montserrat-MediumItalic'), url('./fonts/Montserrat-MediumItalic.ttf') format('truetype');
}
@font-face {
    font-family: 'Vani';
    src: local('Vani'), url('./fonts/Vani.ttf') format('truetype');
}
//////
@font-face {
    font-family: 'HarlowDuoScript';
    src: local('HarlowDuoScript'), url('./fonts/HarlowDuoScript.otf') format('opentype');
}
@font-face {
    font-family: 'HarlowDuoSerif';
    src: local('HarlowDuoSerif'), url('./fonts/HarlowDuoSerif.otf') format('opentype');
}
@font-face {
    font-family: 'Hermitage';
    src: local('Hermitage'), url('./fonts/Hermitage.otf') format('opentype');
}
@font-face {
    font-family: 'SoftWhisperingsBold';
    src: local('SoftWhisperingsBold'), url('./fonts/SoftWhisperingsBold.otf') format('opentype');
}
@font-face {
    font-family: 'TheSeasons-Reg';
    src: local('TheSeasons-Reg'), url('./fonts/TheSeasons-Reg.otf') format('opentype');
}
/////
//@font-face {
//    font-family: 'Barbie-Movie';
//    src: local('Barbie-Movie'), url('./fonts/Barbie-Movie.woff') format('woff');
//}
@font-face {
    font-family: 'SapphireScript';
    src: local('SapphireScript'), url('./fonts/SapphireScript.otf') format('opentype');
}
@font-face {
    font-family: 'VanillaCupcake';
    src: local('VanillaCupcake'), url('./fonts/VanillaCupcake.ttf') format('truetype');
}
@import './App';
@import '~bootstrap/scss/bootstrap.scss';

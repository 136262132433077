@import "~bootstrap/scss/_functions.scss";
@import "~bootstrap/scss/_variables.scss";
@import "~bootstrap/scss/mixins/_breakpoints.scss";

.rectangle-proof-wrapper {
    width: 475px;
    height: 250px;
    position: relative;
    border: 1px solid black;
    background-size: cover;
    background-repeat: no-repeat;
}
.first-name {
    width: 360px;
    position: absolute;
    left: 8%;
    top: 19%;
    height: 100px;
    text-align: center;
    &.rectangle-proof {
        top: 8%;
    }
}
.middle-name {
    width: 230px;
    position: absolute;
    left: 38%;
    top: 49%;
    height: 69px;
    text-align: center;
    &.rectangle-proof {
        top: 55%;
    }
}

.circle {
    height: 400px;
    width: 400px;
    position: relative;
    @include media-breakpoint-down(sm) {
        width: 100%;
        height: auto;
        .first-name {
            width: 270px;
        }
        .middle-name {
            width: 170px;
        }
    };
}

.bg-option-wrapper {
    height: 140px;
    align-items: center;
    border-radius: 5px;
    &.selected {
        border: solid 1px lightgray;
    }
    .bg-option-tile {
        height: 120px;
        width: 90px;
        display: flex;
        flex-direction: column;
        align-Items: center;
        text-align: center;
        &:hover {
            cursor: pointer;
        }
        .option-color {
            height: 60px;
            width: 60px;
            border-radius: 50%;
            background-size: cover;
        }
        p {
            margin: 8px 0 0 0;
        }
    }
}

.disclaimer-slide {
    height: 335px;
    @include media-breakpoint-down(sm) {
        height: auto;
    }
}
